import { NextRouter } from "next/router";
import { safeLocalStorage } from "../../utils/safeLocalStorage";

export const redirectToLogin = (router?: NextRouter) => {
  const currentPathName = window.location.pathname;
  safeLocalStorage.removeItem("__AT");
  safeLocalStorage.removeItem("__RT");
  safeLocalStorage.removeItem("user-id");
  safeLocalStorage.removeItem("user-org-id");
  sessionStorage.removeItem("__PLATFORM_CONNECT_ACCESS_TOKEN");

  if (
    currentPathName.startsWith("/login") ||
    currentPathName.startsWith("/forgot-password") ||
    currentPathName.startsWith("/reset-password") ||
    currentPathName.startsWith("/register")
  )
    return;

  if (typeof router?.push === "function") {
    router.push({
      pathname: "/login",
      query: {
        // Use window.location in case Next router is not initialized
        returnUrl: `${currentPathName}${window.location.search}`,
      },
    });

    return;
  }

  window.location.href = `/login${
    currentPathName ? `?returnUrl=${currentPathName}` : ""
  }`;
};
