import axios from "axios";
import toast from "react-hot-toast";

import {
  isPlatformConnectPage,
  isUsingPlatformConnectAccessToken,
} from "../../contexts/PlatformConnectProvider";
import { TEST_MODE_STORAGE_KEY } from "../../contexts/TestModeProvider";
import { safeLocalStorage } from "../../utils/safeLocalStorage";
import { isSandboxPage } from "../../features/sandbox";
import { redirectToLogin } from "./redirectToLogin";
import { parseParams } from "../../utils/paramsParser";

export const REDIRECT_TO_LOGIN_STATUS_CODES = [401];

const axiosApiInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE,
  paramsSerializer: (params) => parseParams(params),
});

const axiosVaultInstance = axios.create({});

axiosVaultInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    return Promise.reject({
      response: { data: { status: { errors: [error.response?.data] } } },
    });
  }
);

export function refreshAccessToken() {
  const RT = safeLocalStorage.getItem("__RT");
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.NEXT_PUBLIC_API_BASE}/users/refresh-token/`, {
        refresh: RT,
      })
      .then(function (response) {
        const AT = response.data.access;
        safeLocalStorage.setItem("__AT", AT);
        resolve(AT);
      })
      .catch(function () {
        redirectToLogin();
        reject("");
      });
  });
}

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const isTestMode =
      safeLocalStorage.getItem(TEST_MODE_STORAGE_KEY) === "true";

    if (isUsingPlatformConnectAccessToken()) {
      const urlParams = new URLSearchParams(window.location.search);
      const platformConnectAccessToken = urlParams.get("access_token");

      // Add access_token to the request url
      config.params = {
        ...config.params,
        access_token: platformConnectAccessToken,
      };
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-mh-version": process.env.NEXT_PUBLIC_MH_VERSION,
        ...config.headers,
      };

      return config;
    }

    const access_token = safeLocalStorage.getItem("__AT");
    config.headers = {
      Authorization: `Bearer ${access_token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-mh-version": process.env.NEXT_PUBLIC_MH_VERSION,
      ...config.headers,
    };

    if (!config.params) {
      config.params = {};
    }

    config.headers["is-live"] = !isTestMode;

    if (isSandboxPage()) {
      return config;
    }

    // NOTE: this handles the case when urls ends with /%23/ which is /#/
    // which is a valid url but it's not a valid url for our app
    if (
      config.method === "get" &&
      (config.url.endsWith("/#/") || config.url.endsWith("/?/"))
    ) {
      return Promise.reject({
        response: {
          data: {
            status: {
              code: 404,
              errors: [
                {
                  detail:
                    "Frontend check didn't pass as URL shouldn't end with /{#|?}/",
                },
              ],
            },
          },
        },
      });
    }

    // Add is_live flag based on testMode
    if (config.method === "get") {
      if (config.params instanceof URLSearchParams) {
        config.params.append("is_live", !isTestMode);
      } else {
        config.params["is_live"] = !isTestMode;
      }
    } else {
      if (config.headers["Content-Type"] !== "multipart/form-data") {
        config.data = {
          ...config.data,
          is_live: !isTestMode,
        };
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response?.status === 500) {
      toast.error("Something went wrong, please try again.", {
        id: "generic-error",
        // Sticks until any route change
        duration: Infinity,
      });
    }

    if (
      REDIRECT_TO_LOGIN_STATUS_CODES.includes(error.response?.status) &&
      !originalRequest._retry
    ) {
      if (isPlatformConnectPage() && isUsingPlatformConnectAccessToken()) {
        // No login redirection if in platform connect mode (not using JWT)
        return Promise.reject(error);
      }

      // Logout Inactive & Deleted users
      if (
        ["User not found", "User is inactive"].includes(
          error.response?.data.status?.errors[0]?.detail
        )
      ) {
        redirectToLogin();
        return;
      }

      originalRequest._retry = true;

      // retry original request after refreshing access token
      try {
        const AT = await refreshAccessToken();
        originalRequest.headers["Authorization"] = `Bearer ${AT}`;
        const response = await axios.request(originalRequest);

        return Promise.resolve(response);
      } catch (error) {
        console.error(error);
      }
    } else if (
      REDIRECT_TO_LOGIN_STATUS_CODES.includes(error.response?.status)
    ) {
      redirectToLogin();
    }

    return Promise.reject(error);
  }
);

export { axiosApiInstance, axiosVaultInstance };
