import Script from "next/script";

const AppCues = () => {
  // if (process.env.NEXT_PUBLIC_ENV !== "production") return null;

  return (
    <>
      <Script id="app-cues" type="text/javaScript">
        {`window.AppcuesSettings = { enableURLDetection: true };`}
      </Script>
      <Script
        strategy="afterInteractive"
        src="//fast.appcues.com/203430.js"
      />
    </>
  );
};

export default AppCues;
