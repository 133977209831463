// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const initSentry = () => {
  // NOTE: don't initialize Sentry on development
  if (
    !["staging", "production"].includes(process.env.NEXT_PUBLIC_ENV) ||
    process.env.NODE_ENV !== "production"
  )
    return;

  Sentry.init({
    ignoreErrors: [
      "Non-Error promise rejection captured",
      /AxiosError/i,
      /CanceledError/i,
      /chrome-extension/i,
    ],
    beforeSend(event) {
      return event;
    },
    environment: process.env.NEXT_PUBLIC_ENV,
    dsn:
      SENTRY_DSN ||
      "https://1273164b557d42d1957f2b57737ad30b@o450450.ingest.sentry.io/6300022",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });

  Sentry.setTag("mh-version", process.env.NEXT_PUBLIC_MH_VERSION);
};

initSentry();
