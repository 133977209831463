import { Userpilot } from "userpilot";
import { format } from "date-fns";

type ThirdPartyAttrs = {
  id: number;
  is_mh_superuser: boolean;
  is_org_user: boolean;
  email: string;
  name: string;
  organization_id: number;
  organization_name: string;
  persona: string;
  has_completed_dashboard_onboarding: boolean;
  trial_end_date: string | null;
  date_joined: string | null;
};

export const identifyThirdParties = ({
  id,
  is_org_user,
  is_mh_superuser,
  email,
  name,
  organization_name,
  organization_id,
  persona,
  has_completed_dashboard_onboarding,
  trial_end_date,
  date_joined,

}: ThirdPartyAttrs) => {
  try {
    const userPilotPayload = {
      id,
      name,
      email,
      persona,
      is_org_admin: is_org_user,
      is_mh_superuser,
      has_completed_dashboard_onboarding,
      trial_end_date:
        trial_end_date && format(new Date(trial_end_date), "yyyy-MM-dd"),
      // NOTE: this is exactly the same as trial_end_date
      // need it for a hack on UserPilot
      humanized_trial_end_date:
        trial_end_date && format(new Date(trial_end_date), "yyyy-MM-dd"),
      environment: process.env.NEXT_PUBLIC_ENV,
      has_active_trial: !!trial_end_date,
      date_joined: date_joined && format(new Date(date_joined), "yyyy-MM-dd"),
      company: {
        id: organization_id,
        name: organization_name,
        trial_end_date,
        has_active_trial: !!trial_end_date,
      },
    };
    Userpilot.identify(email, userPilotPayload);

    // Keep this for staging & development
    if (process.env.NEXT_PUBLIC_ENV !== "production") {
      console.info("USERPILOT:identify", userPilotPayload);
    }

    const appCuesPayload = {
      ...userPilotPayload,
      company: undefined,
      organization_id,
      organization_name,
      trial_end_date,
      has_active_trial: !!trial_end_date,
    };
    window.Appcues.identify(email, appCuesPayload);

    window.zE?.("webWidget", "identify", {
      id,
      name,
      email,
      organization: organization_name,
      persona,
      is_org_admin: is_org_user,
      is_mh_superuser,
      trial_end_date:
        trial_end_date && format(new Date(trial_end_date), "yyyy-MM-dd"),
      date_joined: date_joined && format(new Date(date_joined), "yyyy-MM-dd"),
    });
  } catch (e) {
    console.warn("Error identifying user in third-party services", e);
    // do nothing
    // never break the app for a third-party method call
  }
};
