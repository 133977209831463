import { isValidElement } from "react";
import toast, { ToastBar, Toaster, type ToastType } from "react-hot-toast";
import { Alert, type AlertProps } from "ui";

const ToastTypeMap: Record<ToastType, AlertProps["state"]> = {
  success: "success",
  error: "danger",
  loading: "informative",
  custom: "neutral",
  blank: undefined,
};

export const ToastWrapper = () => (
  <Toaster
    position="top-center"
    containerClassName="text-sm"
    containerStyle={{
      top: 120,
    }}
  >
    {(t) => (
      <ToastBar
        toast={t}
        style={{
          width: "100%",
          padding: 0,
          maxWidth: "25rem",
          textAlign: "left",
        }}
      >
        {() => (
          <Alert
            title={buildMessage(t.message)}
            state={ToastTypeMap[t.type]}
            contrast="medium"
            onClose={() => toast.dismiss(t.id)}
          />
        )}
      </ToastBar>
    )}
  </Toaster>
);

const buildMessage = (message: unknown): React.ReactNode => {
  if (isValidElement(message)) return message;
  if (typeof message === "string") return message;
  if (message instanceof Object) {
    const errors = Object.entries(message);
    return (
      <ul>
        {errors.map(([key, value], index) => (
          <li key={index}>
            {key} : {ObjectValue(value)}
          </li>
        ))}
      </ul>
    );
  }
  return message;
};

const ObjectValue = (value: any) => {
  if (typeof value === "string" || typeof value?.[0] === "string") return value;

  if (Array.isArray(value)) {
    return (
      <ul className="list-disc pl-6">
        {value?.map((nestedValue: any) => {
          const errorKeys = Object.entries(nestedValue);
          return errorKeys.map(([key, err], index) => (
            <li key={index}>
              {key}: {err}
            </li>
          ));
        })}
      </ul>
    );
  }

  if (value instanceof Object) {
    const errors = Object.entries(value);
    return (
      <ul className="list-disc pl-6">
        {errors.map(([key, value], index) => (
          <li key={index}>
            {key} : {ObjectValue(value)}
          </li>
        ))}
      </ul>
    );
  }

  return value;
};
