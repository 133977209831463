import { useRouter } from "next/router";
import * as React from "react";
import { useLocalStorage } from "react-use";
import { ErrorResponse, useMoneyHashQuery } from "../hooks/useMoneyHashQuery";
import {
  axiosApiInstance,
  REDIRECT_TO_LOGIN_STATUS_CODES,
  refreshAccessToken,
} from "../pages/api/axios";
import { isJWTAuthorized } from "../pages/api/isJWTAuthorized";
import { getUserRole } from "../utils/getUserRole";
import { identifyThirdParties } from "../utils/thirdParties/identify";

type UserContextValue = {
  id?: string;
  setId: React.Dispatch<React.SetStateAction<string | undefined>>;
  name: string | undefined;
  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
  email: string | undefined;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  role: string | undefined;
  setRole: React.Dispatch<React.SetStateAction<string | undefined>>;
  organizationName: string | undefined;
  setOrganizationName: React.Dispatch<React.SetStateAction<string | undefined>>;
  organizationId: string | undefined;
  setOrganizationId: React.Dispatch<React.SetStateAction<string | undefined>>;
  isOnboarded: boolean | undefined;
  setIsOnboarded: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isLoading: boolean | null;
  isSuperUser: boolean | null;
  currentUser?: CurrentUser;
};

type CurrentUser = {
  id: number;
  is_superuser: boolean;
  is_mh_superuser: boolean;
  is_mh_user: boolean;
  is_org_user: boolean;
  email: string;
  name: string;
  organization_name: string;
  organization_id: number;
  has_completed_dashboard_onboarding: boolean;
  persona: string;
  trial_end_date: string | null;
  date_joined: string | null;
};

const UserContext = React.createContext<UserContextValue | null>(null);

const UserProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const [name, setName] = useLocalStorage<string>("username");
  const [id, setId] = useLocalStorage<string>("user-id");
  const [email, setEmail] = useLocalStorage<string>("email");
  const [role, setRole] = useLocalStorage<string>("user-role");
  const [organizationName, setOrganizationName] =
    useLocalStorage<string>("user-org-name");
  const [organizationId, setOrganizationId] =
    useLocalStorage<string>("user-org-id");
  const [isOnboarded, setIsOnboarded] = useLocalStorage<boolean>(
    "has_completed_dashboard_onboarding",
    false
  );
  const checkIsAuthError = (error: ErrorResponse | null) =>
    error?.response?.data?.status?.code &&
    REDIRECT_TO_LOGIN_STATUS_CODES.includes(
      error?.response?.data?.status?.code
    );

  const { isLoading, data: currentUser } = useMoneyHashQuery<CurrentUser>({
    queryKey: ["current-user"],
    onSuccess: (response) => {
      setRole(getUserRole(response.data));
      identifyThirdParties(response.data);
    },
    onError: async (error: ErrorResponse) => {
      // NOTE: this request happens at the very top, once the app is initialized
      // to avoid continuing the requests chain, we try to refresh the access token right away
      // if can't be refreshed, it will redirect immediately.
      // Meanwhile, we should keep using refreshAccessToken inside axios interceptor
      // in case the tab kept open until the token expires
      if (checkIsAuthError(error)) {
        await refreshAccessToken();
      }
    },
    queryFn: ({ signal }) =>
      axiosApiInstance
        .get<{
          data: CurrentUser;
        }>(`/d/users/current/`, { signal })
        .then((res) => {
          return res.data;
        }),
    enabled: router.isReady && !router.query.access_token && isJWTAuthorized(),
  });

  const value = {
    id,
    name,
    setName,
    email,
    setEmail,
    role,
    setId,
    setRole,
    organizationName,
    setOrganizationName,
    organizationId,
    setOrganizationId,
    isOnboarded,
    setIsOnboarded,
    isSuperUser: currentUser?.is_mh_superuser ?? null,
    isMoneyHashUser: currentUser?.is_mh_user ?? null,
    currentUser,
    isLoading: isLoading,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }

  return context;
};

export default UserProvider;
