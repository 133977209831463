import { useRouter } from "next/router";
import { useUserContext } from "../../contexts/UserProvider";
import { axiosApiInstance } from "../../pages/api/axios";
import { sleep } from "../../utils/sleep";
import { useMoneyHashQuery, type QueryResponse } from "../useMoneyHashQuery";

const featureFlagList = [
  "routing_request_extra_actions",
  "apple_pay_configuration",
  "new_dashboard_summaries",
] as const;

type Feature = (typeof featureFlagList)[number];

type FeatureItemResponse = {
  enabled: boolean;
  name: Feature;
}[];

export type FeatureFlags = Record<Feature, boolean>;

export function useFeatureFlags() {
  const { id: userId, organizationId } = useUserContext();
  const router = useRouter();

  const { data, isLoading } = useMoneyHashQuery<Record<Feature, boolean>>({
    queryKey: [
      "featureFlags",
      { names: featureFlagList.join(","), userId, organizationId },
    ],
    queryFn: async () => {
      const featureFlags = axiosApiInstance
        .get<QueryResponse<FeatureItemResponse>>("/features/", {
          params: {
            names: featureFlagList.join(","),
            user_id: userId,
            organization_id: organizationId,
          },
        })
        .then((res) => {
          return {
            ...res.data,
            data: res.data.data.reduce(
              (acc, feature) => {
                acc[feature.name] = feature.enabled;
                return acc;
              },
              {} as Record<Feature, boolean>
            ),
          };
        });

      await sleep(500);
      return featureFlags;
    },
    staleTime: Infinity,
    enabled:
      (!!userId && !!organizationId) ||
      (router.isReady && !!router.query.access_token), // is in platformConnect mode
  });

  return {
    featureFlags: data || ({} as Record<Feature, boolean>),
    isLoadingFeatureFlags: isLoading,
  };
}
