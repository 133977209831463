import type { DropdownOption } from "ui";

import type { Scenario } from "./types";
import { axiosApiInstance } from "../../pages/api/axios";

export const isSandboxPage = () =>
  window?.location?.pathname.startsWith("/sandbox");

export const getAccountsWithSearch = (
  search?: string
): Promise<DropdownOption<string>[]> =>
  axiosApiInstance
    .get<{ data: { columns: { id: string; name: string } }[] }>(
      "/d/accounts/",
      {
        params: {
          search,
          offset: 0,
          limit: 10,
        },
      }
    )
    .then((res) => {
      if (!res.data?.data?.length) {
        return [];
      }

      return res.data.data.map(({ columns }) => [columns.id, columns.name]);
    });

export const getAccountAPIKeysWithSearch = ({
  search,
  account,
  isLive,
}: {
  search: string;
  account: string;
  isLive: boolean;
}): Promise<DropdownOption<{ name: string; key: string }>[]> =>
  axiosApiInstance
    .get<{ data: { columns: { name: string }; preview: { key: string } }[] }>(
      "/d/apikeys/account_apikey/",
      {
        params: {
          search,
          account,
          is_live: isLive,
          offset: 0,
          limit: 10,
        },
      }
    )
    .then((res) => {
      if (!res.data?.data?.length) {
        return [];
      }

      return res.data.data.map(({ columns, preview }) => [
        preview.key,
        {
          name: columns.name,
          key: preview.key,
        },
      ]);
    });

export const getOrganizationAPIKeysWithSearch = (
  search: string
): Promise<DropdownOption<{ name: string; key: string }>[]> =>
  axiosApiInstance
    .get<{ data: { columns: { name: string }; preview: { key: string } }[] }>(
      "/d/apikeys/org_apikey/",
      {
        params: {
          search,
          offset: 0,
          limit: 10,
        },
      }
    )
    .then((res) => {
      if (!res.data?.data?.length) {
        return [];
      }

      return res.data.data.map(({ columns, preview }) => [
        preview.key,
        {
          name: columns.name,
          key: preview.key,
        },
      ]);
    });

export const getSandboxConfig = ({
  type,
  signal,
}: {
  type: string;
  signal?: AbortSignal;
}) =>
  axiosApiInstance
    .get<{
      data: {
        sandbox_secret: string;
        account_api_key: string;
        organization_api_key: string;
        scenarios: Record<string, Scenario>;
      };
    }>(`/d/sandbox/scenarios/${type}/`, {
      signal,
    })
    .then((res) => res.data);
