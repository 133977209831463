import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import {
  isPlatformConnectPage,
  isUsingPlatformConnectAccessToken,
} from "../contexts/PlatformConnectProvider";
import { redirectToLogin } from "../pages/api/redirectToLogin";
import { isJWTAuthorized } from "../pages/api/isJWTAuthorized";

export { RouteGuard };

type RouteGuardProps = {
  children: React.ReactNode;
};

function RouteGuard({ children }: RouteGuardProps) {
  const router = useRouter();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // on initial load - run auth check
    if (!router.isReady) return;
    authCheck();

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false);
    // router.events.on('routeChangeStart', hideContent)

    // on route change complete - run auth check
    router.events.on("routeChangeComplete", authCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off("routeChangeStart", hideContent);
      router.events.off("routeChangeComplete", authCheck);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  function authCheck() {
    // Auth with access token check
    if (isUsingPlatformConnectAccessToken() && !isPlatformConnectPage()) {
      return router.push({
        pathname: router.asPath,
      });
    }

    if (isUsingPlatformConnectAccessToken() && isPlatformConnectPage()) {
      return setAuthorized(true);
    }

    if (!isJWTAuthorized()) {
      setAuthorized(false);
      redirectToLogin(router);
    } else {
      setAuthorized(true);
    }
  }

  if (!authorized) {
    return null;
  }

  return authorized && children;
}
