import { useState } from "react";

export function useHighlight() {
  const [isHighlighted, setIsHighlighted] = useState(false);

  const highlight = () => setIsHighlighted(true);
  const onAnimationEnd = () => setIsHighlighted(false);

  return [isHighlighted, highlight, onAnimationEnd] as const;
}
