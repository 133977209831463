import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import type { Method } from "axios";

import { Button, OverflowText, cn, DropdownSearch, DropdownOption } from "ui";
import { CheckIcon, DuplicateIcon, FolderAddIcon } from "icons/outline";
import { SandboxConfigSection } from "./SandboxConfigSection";
import {
  getAccountAPIKeysWithSearch,
  getAccountsWithSearch,
  getOrganizationAPIKeysWithSearch,
} from "../utils";
import { JsonEditor } from "./JsonEditor";
import { EndPoint } from "./EndPoint";
import { Addon, Scenario } from "../types";
import { UrlValues } from "./UrlValues";
import { axiosApiInstance, axiosVaultInstance } from "../../../pages/api/axios";
import { parseErrorsToObject } from "../../../utils/parseErrors";
import { toast } from "react-hot-toast";
import { Spinner } from "../../../components/Icons/Spinner";
import { scenarioTypeOptions, useSandbox } from "../SandboxProvider";

interface SandboxConfigProps {
  onHideEmbedChange: (hide: boolean) => void;
  onResponseChange: (response: Record<string, any>) => void;
}

export function SandboxConfig({
  onHideEmbedChange,
  onResponseChange,
}: SandboxConfigProps) {
  const {
    setUrlValues,
    isSubmitting,
    setIsSubmitting,
    account,
    setAccount,
    accountApiKey,
    organizationApiKey,
    isLive,
    setIsLive,
    isMHDemoKey,
    type,
    setType,
    editor,
    copy,
    isEditorHighlighted,
    highlightEditor,
    onHighlightEditorAnimationEnd,
    sandboxConfig,
    isLoading,
    mappedUrl,
    scenario,
    setScenario,
    setEditor,
    setEndPoint,
    setAccountApiKey,
    urlValues,
    endpoint,
    setOrganizationApiKey,
  } = useSandbox();

  const isInDevelopment = ["dev", "development"].includes(
    process.env.NEXT_PUBLIC_ENV as string
  );

  useEffect(() => {
    if (!scenario) return;
    const { endpoint, method, parameter_values, editor, has_embed } =
      scenario[1];
    setEndPoint({ url: endpoint, method });
    setEditor(JSON.stringify(editor, null, 2));
    onHideEmbedChange(!has_embed);
    onResponseChange({});

    /**
     * Filter out all invalid URL parameters that are not mapped in URL
     */
    const validUrlValues = endpoint
      .match(/(\$\{\w+\})/g)
      ?.map((key) => key.replace(/\$\{(\w+)\}/, "$1"));
    setUrlValues(
      validUrlValues && parameter_values
        ? Object.fromEntries(
            validUrlValues.map((key) => [key, parameter_values[key]])
          )
        : null
    );
  }, [
    scenario,
    onHideEmbedChange,
    onResponseChange,
    setEndPoint,
    setEditor,
    setUrlValues,
  ]);

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-foundations-default/50">
          <Spinner className="h-20 w-20 text-info" />
        </div>
      )}

      <div className="space-y-6 ">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button
            variant="secondary"
            onClick={() => {
              setType(scenarioTypeOptions[0]);
              setAccount(["", "MoneyHash Demo Account"]);
            }}
          >
            Reset to default
          </Button>
          <Button
            disabled={!sandboxConfig}
            isLoading={isSubmitting}
            onClick={() => {
              if (
                scenario?.[1].api_key_type === "ORGANIZATION_KEY" &&
                !organizationApiKey?.[0]
              ) {
                toast.error("Please select an Organization API Key.");
                return;
              }

              if (
                scenario?.[1].api_key_type === "ACCOUNT_KEY" &&
                !accountApiKey &&
                !scenario?.[1].disable_headers
              ) {
                toast.error("Please select an API Key.");
                return;
              }

              let editorValue = {};
              try {
                editorValue = JSON.parse(editor);
              } catch (error) {
                toast.error(
                  "The provided JSON is not valid. Please ensure that your JSON payload is correctly formatted."
                );
                return;
              }
              setIsSubmitting(true);

              const request =
                mappedUrl.includes("vault") ||
                (isInDevelopment && mappedUrl.includes("http://localhost:8001"))
                  ? axiosVaultInstance({
                      method: endpoint.method as Method,
                      url: mappedUrl as string,
                      headers: {
                        "Mh-Authorization":
                          (editorValue as { access_token: string })
                            .access_token ||
                          scenario?.[1].parameter_values?.vault_token ||
                          "",
                        "x-api-key":
                          scenario?.[1].api_key_type === "ORGANIZATION_KEY"
                            ? organizationApiKey?.[0]!
                            : accountApiKey?.[0] ||
                              sandboxConfig?.account_api_key!,
                      },
                      data: {
                        ...editorValue,
                      },
                    })
                  : axiosApiInstance({
                      method: endpoint.method as Method,
                      url: mappedUrl?.startsWith("https")
                        ? mappedUrl
                        : `${process.env.NEXT_PUBLIC_BACKEND_URL}${mappedUrl}`,
                      headers: {
                        "x-api-key":
                          scenario?.[1].api_key_type === "ORGANIZATION_KEY"
                            ? organizationApiKey?.[0]!
                            : accountApiKey?.[0] ||
                              sandboxConfig?.account_api_key!,
                      },
                      data: {
                        secret: sandboxConfig?.sandbox_secret,
                        ...editorValue,
                      },
                    });

              request
                .then((response) => onResponseChange(response.data))
                .catch((error) => {
                  onResponseChange(error.response.data);

                  const message =
                    "An error occurred while creating the intent. Please check JSON Response and try again.";
                  toast.error(message, {
                    id: "sandbox",
                    duration: 4000,
                  });
                })
                .finally(() => setIsSubmitting(false));
            }}
          >
            Submit
          </Button>
        </div>

        <SandboxConfigSection title="Setup">
          {scenario?.[1].api_key_type === "ORGANIZATION_KEY" ? (
            <div className="flex flex-col space-y-1">
              <span className="text-sm font-medium text-foundations-bold">
                Organization API Key
              </span>
              <DropdownSearch
                label="Select API key"
                resettable={false}
                selected={organizationApiKey}
                onSelect={setOrganizationApiKey}
                getOptions={getOrganizationAPIKeysWithSearch}
                displayedValueKey="key"
                renderOption={({ name, key }) => (
                  <>
                    <div className="flex flex-col space-y-1.5 overflow-hidden">
                      <OverflowText size="sm" disableHoverableContent>
                        <span>{key}</span>
                      </OverflowText>
                      <OverflowText size="sm" disableHoverableContent>
                        <span>{name}</span>
                      </OverflowText>
                    </div>
                    <CheckIcon className="invisible h-5 w-5 shrink-0 group-data-[selected=true]:visible" />
                  </>
                )}
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col space-y-1">
                <span className="text-sm font-medium text-foundations-bold">
                  Account
                </span>
                <DropdownSearch
                  label="Select an account"
                  selected={account}
                  onSelect={setAccount}
                  getOptions={getAccountsWithSearch}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <span className="text-sm font-medium text-foundations-bold">
                  API Key
                </span>
                <DropdownSearch
                  label="Select API key"
                  resettable={false}
                  disabled={scenario?.[1].disable_headers || isMHDemoKey}
                  selected={accountApiKey}
                  onSelect={setAccountApiKey}
                  getOptions={(search) =>
                    getAccountAPIKeysWithSearch({
                      search,
                      account: account?.[0]!,
                      isLive,
                    })
                  }
                  dependencies={[isLive, account?.[0]]}
                  displayedValueKey="key"
                  renderOption={({ name, key }) => (
                    <>
                      <div className="flex flex-col space-y-1.5 overflow-hidden">
                        <OverflowText size="sm" disableHoverableContent>
                          <span>{key}</span>
                        </OverflowText>
                        <OverflowText size="sm" disableHoverableContent>
                          <span>{name}</span>
                        </OverflowText>
                      </div>
                      <CheckIcon className="invisible h-5 w-5 shrink-0 group-data-[selected=true]:visible" />
                    </>
                  )}
                  ExtraBodyContent={
                    <div className="flex space-x-4 border-b border-foundations-subtle px-4 text-sm">
                      <button
                        onClick={() => setIsLive(true)}
                        className={cn(
                          "border-b-2 px-2 py-2.5 font-medium",
                          isLive
                            ? "border-b-info-bold text-foundations-boldest"
                            : "border-b-transparent text-foundations-subtler"
                        )}
                      >
                        Live
                      </button>
                      <button
                        onClick={() => setIsLive(false)}
                        className={cn(
                          "border-b-2 px-2 py-2.5 font-medium",
                          !isLive
                            ? "border-b-info-bold text-foundations-boldest"
                            : "border-b-transparent text-foundations-subtler"
                        )}
                      >
                        Test
                      </button>
                    </div>
                  }
                  contentProps={{ className: "max-w-sm", align: "start" }}
                />
              </div>
            </div>
          )}
        </SandboxConfigSection>

        <SandboxConfigSection title="Scenario">
          <DropdownSearch
            prefixText="Type"
            label="Select an type"
            selected={type}
            onSelect={setType}
            options={scenarioTypeOptions}
            resettable={false}
            displayedValueKey="label"
            renderOption={({ label, Icon }) => (
              <div className="flex items-center space-x-3">
                <Icon
                  className={cn(
                    "h-5 w-5 text-foundations-subtler group-data-[highlighted=true]:text-foundations-inverse group-data-[selected=true]:text-foundations-inverse"
                  )}
                />
                <span>{label}</span>
              </div>
            )}
          />
          <ScenariosDropdown
            scenario={scenario}
            onScenarioChange={setScenario}
            sandboxScenariosConfig={
              sandboxConfig ? sandboxConfig.scenarios : null
            }
          />
        </SandboxConfigSection>

        <SandboxConfigSection title="Inputs">
          <AddonsDropdown
            addonsConfig={scenario ? scenario[1]?.addons : null}
            onSelect={(addon) => {
              try {
                const editorValue = JSON.parse(editor);
                setEditor(
                  JSON.stringify({ ...editorValue, ...addon }, null, 2)
                );
                highlightEditor();
              } catch (error) {
                toast.error(
                  "The provided JSON is not valid. and couldn't insert addon automatically but it's copied to your clipboard."
                );
                copy(JSON.stringify(addon, null, 2).slice(1, -1).trim());
                return;
              }
            }}
          />
          <JsonEditor
            value={editor}
            onChange={setEditor}
            className={isEditorHighlighted ? "animate-highlight" : ""}
            onAnimationEnd={onHighlightEditorAnimationEnd}
          />

          {mappedUrl && <EndPoint method={endpoint.method} url={mappedUrl} />}

          {urlValues && (
            <UrlValues
              urlValues={urlValues}
              setUrlValues={
                setUrlValues as Dispatch<
                  SetStateAction<Record<string, string> | null>
                >
              }
            />
          )}
        </SandboxConfigSection>
      </div>
    </>
  );
}

const ScenariosDropdown = ({
  scenario,
  onScenarioChange,
  sandboxScenariosConfig,
}: {
  scenario: DropdownOption<Scenario> | null;
  onScenarioChange: (scenario: DropdownOption<Scenario>) => void;
  sandboxScenariosConfig: Record<string, any> | null;
}) => {
  const scenarioOptions = useMemo(() => {
    if (!sandboxScenariosConfig) return [];

    return Object.entries(sandboxScenariosConfig).map(([key, value]) => [
      key,
      { ...value, label: key },
    ]) as DropdownOption<Scenario & { label: string }>[];
  }, [sandboxScenariosConfig]);

  // Persist scenario selection on API key changes
  useEffect(() => {
    if (!scenarioOptions.length) return;

    if (!scenario) {
      onScenarioChange(scenarioOptions[0]);
    } else {
      onScenarioChange(
        scenarioOptions.find(([optionValue]) => optionValue === scenario[0]) ??
          scenarioOptions[0]
      );
    }
  }, [scenario, scenarioOptions, onScenarioChange]);

  return (
    <DropdownSearch
      className="[&_.value]:capitalize"
      prefixText="Scenario"
      label="Choose a scenario"
      options={scenarioOptions}
      selected={scenario}
      onSelect={onScenarioChange}
      renderOption={({ label, description }) => (
        <div className="flex flex-1 items-center justify-between space-x-1">
          <div className="flex-1 space-y-1.5 capitalize">
            <p className="font-medium">{label}</p>
            {description && <p>{description}</p>}
          </div>
          <CheckIcon className="invisible h-5 w-5 shrink-0 group-data-[selected=true]:visible" />
        </div>
      )}
      displayedValueKey="label"
      resettable={false}
    />
  );
};

const AddonsDropdown = ({
  addonsConfig,
  onSelect,
}: {
  addonsConfig: Scenario["addons"];
  onSelect: (addon: DropdownOption<Addon>["1"]["editor"]) => void;
}) => {
  const addonsOptions = useMemo(() => {
    if (!addonsConfig) return [];

    return Object.entries(addonsConfig).map(([key, value]) => [
      key,
      { ...value, label: key },
    ]) as DropdownOption<Addon & { label: string }>[];
  }, [addonsConfig]);

  return (
    <DropdownSearch<
      Addon & {
        label: string;
      }
    >
      prefixIcon={FolderAddIcon}
      label="Add-ons"
      selected={null}
      onSelect={async ([_, { editor }]) => {
        onSelect(editor);
      }}
      options={addonsOptions}
      filterBy={(query, { label }) =>
        label.toLowerCase().includes(query.toLowerCase())
      }
      displayedValueKey="label"
      renderOption={({ label, description }) => (
        <div className="flex flex-1 items-center justify-between space-x-1 text-left">
          <div className="flex-1 space-y-1.5">
            <p className="font-medium">{label}</p>
            {description && <p>{description}</p>}
          </div>
          <DuplicateIcon className="h-5 w-5 shrink-0" />
        </div>
      )}
    />
  );
};
