import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN!, {
  api_transport: "sendBeacon",
  persistence: "localStorage",
  // debug: true,
});

type ModuleEvents = {
  customers: ["tab_changed", "card_token_copied", "action"];
  insights: [
    "set_account",
    "set_duration",
    "view_details",
    "tab_view",
    "provider_details",
    "method_details",
    "account_details",
    "revenue_details",
    "transaction_details",
  ];
  routing: [
    "create_flow",
    "add_action",
    "delete_action",
    "edit_action",
    "add_trigger",
    "remove_trigger",
    "order_triggers_usage",
    "triggers_details", // collect all trigger details on publish
    "remove_action",
    "branches_details",
    "publish",
    "version_change",
    "version_rename",
    "version_duplicate",
    "paths_details",
    "trigger_usage",
    "webhook_usage",
    "provider_usage",
  ];
};
type AnalyticsModuleEvents = {
  [k in keyof ModuleEvents]: `${k}_${ModuleEvents[k][number]}`;
}[keyof ModuleEvents];

type ModuleEventsWithVariants = {
  transactions: ["capture", "refund", "void", "export" | "pull"];
};
type AnalyticsModuleEventsWithVariants = {
  [k in keyof ModuleEventsWithVariants]:
    | `${k}_${ModuleEventsWithVariants[k][number]}`
    | `${k}_${ModuleEventsWithVariants[k][number]}_cancel`
    | `${k}_${ModuleEventsWithVariants[k][number]}_confirm`
    | `${k}_${ModuleEventsWithVariants[k][number]}_success`
    | `${k}_${ModuleEventsWithVariants[k][number]}_error`;
}[keyof ModuleEventsWithVariants];

export type Event =
  | AnalyticsModuleEvents
  | AnalyticsModuleEventsWithVariants
  | "page_view";

class AnalyticsManager {
  track(eventName: Event, properties?: Record<string, unknown>) {
    mixpanel.track(eventName, properties);
  }

  reset() {
    mixpanel.reset();
  }
}

export const analytics = new AnalyticsManager();
