import { useMemo, useState } from "react";
import { axiosApiInstance } from "../../../pages/api/axios";
import { cn, DropdownOption, DropdownSearch, useCopyToClipboard } from "ui";
import { CardIcon, DuplicateIcon } from "icons/outline";
import { useMoneyHashQuery } from "../../../hooks/useMoneyHashQuery";
import { toast } from "react-hot-toast";

type Card = {
  description: string;
  provider: string;
  icon: string;
  card_number: string;
  cvv: string;
  expiry_date: string;
};

export function TestCardsDropdown() {
  const { data } = useMoneyHashQuery<{
    failure: Card[];
    success: Card[];
  }>({
    queryKey: ["sandboxTestCards"],
    queryFn: () =>
      axiosApiInstance.get("/d/sandbox/test-cards/").then((res) => res.data),
    staleTime: Infinity,
  });

  const [activeTab, setActiveTab] =
    useState<keyof NonNullable<typeof data>>("success");

  const options = useMemo(() => {
    return {
      success: data?.success.map((card) => [card.card_number, card]),
      failure: data?.failure.map((card) => [card.card_number, card]),
    };
  }, [data]);

  const activeOptions = (options[activeTab] ||
    []) as unknown as DropdownOption<Card>[];

  const { copy } = useCopyToClipboard();

  return (
    <DropdownSearch
      className="my-2 sm:max-w-[217px]"
      disabled={!data}
      prefixIcon={({ className }) => (
        <div
          className={cn(
            className,
            "h-auto w-auto rounded-md border border-neutral-300 px-1.5 py-0.5"
          )}
        >
          <CardIcon className="h-4 w-4 text-gold-400" />
        </div>
      )}
      label="Test Cards"
      placeholder="Search by MoneyHash test cards"
      selected={null}
      onSelect={async ([value]: DropdownOption<Card>) => {
        if (await copy(value)) {
          toast.success("Copied test card successfully.", {
            id: "test-card-copy",
          });
        }
      }}
      options={activeOptions}
      displayedValueKey="card_number"
      filterBy={(query, { card_number, provider }) =>
        provider.toLowerCase().includes(query.toLowerCase()) ||
        card_number.toLowerCase().includes(query.toLowerCase())
      }
      renderOption={({ icon, card_number, expiry_date, cvv, provider }) => (
        <button
          type="button"
          tabIndex={-1}
          className="flex flex-1 items-center space-x-3 text-sm text-foundations-bold group-data-[highlighted=true]:text-foundations-inverse"
          onClick={async () => {
            // Hacky way for some Safari versions that need click handler to copy
            if (await copy(card_number)) {
              toast.success("Copied test card successfully.", {
                id: "test-card-copy",
              });
            }
          }}
        >
          {icon ? (
            <img
              src={icon}
              alt={provider}
              className="h-10 w-10 shrink-0 rounded bg-foundations-subtlest text-black"
            />
          ) : (
            <div className="flex h-10 w-10 shrink-0 items-center justify-center text-gold-400">
              <CardIcon className="h-6 w-6" />
            </div>
          )}
          <div className="flex-1 space-y-1.5">
            <h2 className="text-left">
              {card_number.match(/\d{1,4}/g)?.join(" ")}
            </h2>
            <div className="flex">
              <p className="flex flex-1 items-center space-x-1.5">
                <span className="text-xs font-medium uppercase text-foundations-subtler group-data-[highlighted=true]:text-current">
                  cvv:
                </span>
                <span>{cvv || "Any"}</span>
              </p>
              <p className="flex flex-1 items-center space-x-1.5">
                <span className="text-xs font-medium uppercase text-foundations-subtler group-data-[highlighted=true]:text-current">
                  end date:
                </span>
                <span>{expiry_date}</span>
              </p>
            </div>
          </div>
          <DuplicateIcon className="h-5 w-5 text-foundations-subtle group-data-[highlighted=true]:text-current" />
        </button>
      )}
      ExtraBodyContent={
        <div className="flex space-x-4 border-b border-foundations-subtle px-4 text-sm">
          <button
            onClick={() => setActiveTab("success")}
            className={cn(
              "border-b-2 px-2 py-2.5 font-medium",
              activeTab === "success"
                ? "border-b-blue-500 text-foundations-boldest"
                : "border-b-transparent text-foundations-subtler"
            )}
          >
            Success
          </button>
          <button
            onClick={() => setActiveTab("failure")}
            className={cn(
              "border-b-2 px-2 py-2.5 font-medium",
              activeTab === "failure"
                ? "border-b-blue-500 text-foundations-boldest"
                : "border-b-transparent text-foundations-subtler"
            )}
          >
            Failure
          </button>
        </div>
      }
      contentProps={{ align: "end", className: "sm:max-w-md" }}
    />
  );
}
