import { Fragment, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { CodeIcon, DesktopComputerIcon } from "icons/outline";

import { TestCardsDropdown } from "./TestCardsDropdown";
import { IframePreview } from "./IframePreview";
import { JsonEditor } from "./JsonEditor";
import { EmptyStateIllustration } from "ui";

interface SandboxPreviewProps {
  embedUrl: string;
  response: Record<any, any> | null;
  hideEmbed: boolean;
}

export function SandboxPreview({
  embedUrl,
  response,
  hideEmbed,
}: SandboxPreviewProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    // Open JSON response tab if there is no embed url (i.e there is an error)
    if (response?.data && !response?.data?.embed_url) {
      setSelectedIndex(1);
    }
  }, [response?.data]);

  return (
    <>
      <Tab.Group
        key={`${hideEmbed}`}
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
      >
        <div className="flex flex-col-reverse justify-between gap-2 border-b border-b-foundations-subtle sm:flex-row">
          <Tab.List className="flex space-x-4">
            {!hideEmbed && (
              <Tab
                value="embed"
                className="flex items-center space-x-1.5 border-b-2 border-b-transparent px-2 pb-2.5 pt-1 text-sm font-medium text-foundations-subtle focus:outline-none aria-selected:border-b-blue-500 aria-selected:text-foundations-boldest"
              >
                <DesktopComputerIcon className="h-4 w-4" /> <span>Embed</span>
              </Tab>
            )}
            <Tab className="flex items-center space-x-1.5 border-b-2 border-b-transparent px-2 pb-2.5 pt-1 text-sm font-medium text-foundations-subtle focus:outline-none aria-selected:border-b-blue-500 aria-selected:text-foundations-boldest">
              <CodeIcon className="h-4 w-4" /> <span>JSON Response</span>
            </Tab>
          </Tab.List>

          <TestCardsDropdown />
        </div>

        <Tab.Panels as={Fragment}>
          {!hideEmbed && (
            <Tab.Panel className="mt-4 flex flex-1">
              {embedUrl ? (
                <IframePreview url={embedUrl} />
              ) : (
                <div className="flex flex-1 flex-col items-center justify-center space-y-6 bg-foundations-default px-2">
                  <EmptyStateIllustration />
                  <p className="text-center text-sm font-medium text-foundations-boldest">
                    A payment embed will appear here after clicking submit
                  </p>
                </div>
              )}
            </Tab.Panel>
          )}

          <Tab.Panel className="mt-4 flex flex-1">
            <JsonEditor
              className="w-0 flex-1"
              readOnly
              value={JSON.stringify(response || {}, null, 2)}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}
