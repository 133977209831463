import { createContext, useContext, useEffect } from "react";
import mixpanel from "mixpanel-browser";

import { analytics } from "./analyticsManager";
import { useUserContext } from "../../contexts/UserProvider";
import { useRouter } from "next/router";

type AnalyticsContextType = {
  analytics: typeof analytics;
};

const AnalyticsContext = createContext<AnalyticsContextType>(
  {} as AnalyticsContextType
);

type AnalyticsProviderProps = Omit<AnalyticsContextType, "analytics"> & {
  children: React.ReactNode;
};

export default function AnalyticsProvider({
  children,
}: AnalyticsProviderProps) {
  const router = useRouter();
  const { currentUser } = useUserContext();

  useEffect(() => {
    if (!currentUser) return;

    mixpanel.identify(currentUser.email);
    const { name, email, ...rest } = currentUser;
    mixpanel.people.set({
      $name: name,
      $email: email,
      ...rest,
    });
  }, [currentUser]);

  useEffect(() => {
    analytics.track("page_view", { page_name: router.pathname });
  }, [router.pathname]);

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    console.warn("useAnalytics must be used inside <AnalyticsProvider />");
  }

  return context;
};
