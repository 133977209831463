interface SandboxConfigSectionProps {
  title: string;
  children: React.ReactNode;
}

export function SandboxConfigSection({
  title,
  children,
}: SandboxConfigSectionProps) {
  return (
    <section>
      <h2 className="flex items-center gap-3 text-sm font-medium text-foundations-bold before:h-px before:flex-1 before:bg-foundations-subtler before:content-[''] after:h-px after:flex-1 after:bg-foundations-subtler after:content-['']">
        {title}
      </h2>
      <div className="mt-2 space-y-4">{children}</div>
    </section>
  );
}
