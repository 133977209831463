export const getUserRole = ({
  is_org_user,
  is_mh_superuser,
  is_mh_user,
}: {
  is_org_user: boolean;
  is_mh_superuser: boolean;
  is_mh_user: boolean;
}) => {
if (is_org_user) return "Org Admin";
if (is_mh_superuser) return "Superuser";
if (is_mh_user) return "MoneyHash user";
return "User";
};
