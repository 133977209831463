import { useRouter } from "next/router";
import React, { useCallback, useRef, useState } from "react";

const GlobalSearchProviderContext = React.createContext({
  enableSearch: (_scope: string, _onSearch: (search: string) => void) => {},
  onSearch: (_value: string) => {},
  reset: () => {},
  scope: "",
  globalSearchQuery: "",
});

export const GlobalSearchProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const globalSearchQuery = router.query.search as string;

  const [scope, setScope] = useState<string>("");
  const onSearchRef = useRef<((_search: string) => void) | null>(null);

  const enableSearch = useCallback(
    (scope: string, onSearch: (search: string) => void) => {
      onSearchRef.current = onSearch;
      setScope(scope);
    },
    [onSearchRef]
  );

  const onSearch = useCallback(
    (value: string) => {
      onSearchRef.current?.(value);
    },
    [onSearchRef]
  );

  const reset = useCallback(() => {
    setScope("");
  }, []);

  return (
    <GlobalSearchProviderContext.Provider
      value={{ enableSearch, onSearch, scope, reset, globalSearchQuery }}
    >
      {children}
    </GlobalSearchProviderContext.Provider>
  );
};

export function useGlobalSearch() {
  const context = React.useContext(GlobalSearchProviderContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalSearch must be used within a GlobalSearchProvider"
    );
  }

  return context;
}
