import Script from "next/script";

const Zendesk = () => {
  if (
    !["staging", "production"].includes(process.env.NEXT_PUBLIC_ENV as string)
  )
    return null;

  return (
    <Script
      id="ze-snippet"
      strategy="afterInteractive"
      src="https://static.zdassets.com/ekr/snippet.js?key=00f19622-e80a-4755-8cdd-9fbe8abb1614"
    />
  );
};

export default Zendesk;
