import get from "lodash.get";
import { useRouter } from "next/router";
import React, { useEffect, useState, useCallback } from "react";
import { safeLocalStorage } from "../utils/safeLocalStorage";
import { usePlatformConnect } from "./PlatformConnectProvider";

export const TEST_MODE_STORAGE_KEY = "mh_test_mode";

const noop = () => {};
const defaultValue = {
  isTestMode: false,
  parentPath: "/",
  toggleTestMode: noop,
  checkIsLiveAndRedirect: (
    _pathOrGetPath: string | (() => boolean),
    _entity?: unknown
  ) => {},
};

const TestModeContext = React.createContext(defaultValue);

export const TestModeProvider: React.FC<{ parentPath: string }> = ({
  children,
  parentPath,
}) => {
  const [isTestMode, setIsTestMode] = useState(false);
  const { isPlatformConnectMode } = usePlatformConnect();
  const router = useRouter();

  const toggleTestMode = useCallback(() => {
    setIsTestMode((prev) => {
      safeLocalStorage.setItem(TEST_MODE_STORAGE_KEY, `${!prev}`);
      // NOTE: Don't change the value here, leave it for redirect and it will be
      // picked up from localStorage value
      return prev;
    });
  }, []);

  const checkIsLiveAndRedirect = useCallback(
    (pathOrGetPath: string | (() => boolean), entity?: unknown) => {
      // Don't check if it's platform connect as they don't see TestMode
      if (isPlatformConnectMode) {
        return;
      }
      const isLive =
        typeof pathOrGetPath === "function"
          ? pathOrGetPath()
          : get(entity, pathOrGetPath);

      if (isLive === isTestMode) {
        router.replace("/");
      }
    },
    [isTestMode, router, isPlatformConnectMode]
  );

  useEffect(() => {
    if (isPlatformConnectMode) {
      setIsTestMode(false);
      safeLocalStorage.setItem(TEST_MODE_STORAGE_KEY, `false`);
      return;
    }
    const localStorageIsLive = safeLocalStorage.getItem(TEST_MODE_STORAGE_KEY);

    if (localStorageIsLive !== null) {
      if (localStorageIsLive === "true" && !isTestMode) {
        setIsTestMode(true);
      }
    }
  }, [toggleTestMode, isTestMode, isPlatformConnectMode]);

  return (
    <TestModeContext.Provider
      value={{ isTestMode, toggleTestMode, checkIsLiveAndRedirect, parentPath }}
    >
      {children}
    </TestModeContext.Provider>
  );
};

export function useTestMode() {
  const context = React.useContext(TestModeContext);
  if (context === undefined) {
    throw new Error("useTestMode must be used within a TestModeProvider");
  }

  return context;
}
