import React, { useEffect, useMemo, useState } from "react";
import { DropdownOption, useCopyToClipboard } from "ui";
import {
  CardsIcon,
  Data2Icon,
  IntegrationIcon,
  LinkIcon,
  MoneysIcon,
  PayInProviderIcon,
  PayOutProviderIcon,
  PaymentProviderIcon,
  Receipt1Icon,
  RefreshIcon,
  TransactionsIcon,
  UserCircleIcon,
  UsersIcon,
  Wallet2Icon,
} from "icons/outline";
import {
  ErrorResponse,
  useMoneyHashQuery,
} from "../../hooks/useMoneyHashQuery";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { Scenario } from "./types";
import { useHighlight } from "./useHighlight";
import { getSandboxConfig } from "./utils";

const DEMO_ACCOUNT_DISPLAY_NAME = "MoneyHash Demo Account";

export const scenarioTypeOptions: DropdownOption<{
  label: string;
  Icon: typeof PayInProviderIcon;
}>[] = [
  ["payment", { label: "Payments", Icon: PayInProviderIcon }],
  ["payout", { label: "Payouts", Icon: PayOutProviderIcon }],
  ["customers", { label: "Customers", Icon: UsersIcon }],
  ["cards", { label: "Cards", Icon: CardsIcon }],
  ["accounts", { label: "Accounts", Icon: UserCircleIcon }],
  ["providers", { label: "Providers", Icon: PaymentProviderIcon }],
  ["subscriptions", { label: "Subscription", Icon: Receipt1Icon }],
  ["reconciliation", { label: "Reconciliation", Icon: Data2Icon }],
  ["wallets", { label: "Wallets", Icon: Wallet2Icon }],
  ["payment_links", { label: "Payment Links", Icon: LinkIcon }],
  ["sync", { label: "Sync", Icon: RefreshIcon }],
  ["transactions", { label: "Transactions", Icon: TransactionsIcon }],
  ["bulk_payout_request", { label: "Bulk Payout request", Icon: MoneysIcon }],
  ["external_api", { label: "External API", Icon: IntegrationIcon }],
  ["direct_api", { label: "Direct API", Icon: IntegrationIcon }],
];

type SandboxProviderContextData = {
  setUrlValues: (url: Scenario["parameter_values"] | null) => void;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  account: DropdownOption<string>;
  setAccount: React.Dispatch<React.SetStateAction<DropdownOption<string>>>;
  accountApiKey: DropdownOption<{ name: string; key: string }> | null;
  organizationApiKey: DropdownOption<{ name: string; key: string }> | null;
  isLive: boolean;
  setIsLive: React.Dispatch<React.SetStateAction<boolean>>;
  isMHDemoKey: boolean;
  type: (typeof scenarioTypeOptions)[number];
  setType: React.Dispatch<
    React.SetStateAction<(typeof scenarioTypeOptions)[0]>
  >;
  editor: any; // Replace 'any' with the actual type
  copy: (text: string) => Promise<boolean>;
  isEditorHighlighted: boolean;
  isError: boolean;
  error: ErrorResponse | null;
  highlightEditor: () => void;
  onHighlightEditorAnimationEnd: () => void;
  sandboxConfig: any; // Replace 'any' with the actual type
  isLoading: boolean;
  mappedUrl: string;
  scenario: DropdownOption<Scenario> | null;
  setScenario: React.Dispatch<
    React.SetStateAction<DropdownOption<Scenario> | null>
  >;
  setEditor: React.Dispatch<React.SetStateAction<any>>; // Replace 'any' with the actual type
  setEndPoint: React.Dispatch<
    React.SetStateAction<{
      url: string;
      method: string;
    }>
  >;
  setAccountApiKey: React.Dispatch<
    React.SetStateAction<DropdownOption<{
      name: string;
      key: string;
    }> | null>
  >;
  setOrganizationApiKey: React.Dispatch<
    React.SetStateAction<DropdownOption<{
      name: string;
      key: string;
    }> | null>
  >;
  urlValues: Record<string, string> | null;
  endpoint: {
    url: string;
    method: string;
  };
};

const SandboxProviderContext = React.createContext<SandboxProviderContextData>({
  setUrlValues: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},
  account: ["", "MoneyHash Demo Account"],
  setAccount: () => {},
  accountApiKey: [
    "",
    { key: "MoneyHash API Account", name: DEMO_ACCOUNT_DISPLAY_NAME },
  ],
  organizationApiKey: null,
  isLive: false,
  isError: false,
  setIsLive: () => {},
  isMHDemoKey: false,
  type: scenarioTypeOptions[0],
  setType: () => {},
  editor: null, // Replace 'null' with a suitable default value
  copy: () => Promise.resolve(false),
  isEditorHighlighted: false,
  highlightEditor: () => {},
  onHighlightEditorAnimationEnd: () => {},
  sandboxConfig: null, // Replace 'null' with a suitable default value
  isLoading: false,
  mappedUrl: "",
  scenario: null, // Replace 'null' with a suitable default value
  setScenario: () => {},
  setEditor: () => {},
  setEndPoint: () => {},
  endpoint: { url: "", method: "get" },
  setAccountApiKey: () => {},
  setOrganizationApiKey: () => {},
  error: null,
  urlValues: null,
});

export const SandboxProvider: React.FC = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [account, setAccount] = useState<DropdownOption<string>>([
    "",
    "MoneyHash Demo Account",
  ]);

  const [accountApiKey, setAccountApiKey] = useState<DropdownOption<{
    name: string;
    key: string;
  }> | null>([
    "",
    { key: "MoneyHash API Account", name: DEMO_ACCOUNT_DISPLAY_NAME },
  ]);
  const [organizationApiKey, setOrganizationApiKey] = useState<DropdownOption<{
    name: string;
    key: string;
  }> | null>(null);
  const [isLive, setIsLive] = useState(false);
  const isMHDemoKey = account?.[1] === DEMO_ACCOUNT_DISPLAY_NAME;

  const [type, setType] = useState<
    DropdownOption<{
      label: string;
      Icon: typeof PayInProviderIcon;
    }>
  >(scenarioTypeOptions[0]);
  const [scenario, setScenario] = useState<DropdownOption<Scenario> | null>(
    null
  );

  const [editor, setEditor] = useState<string>("{}");
  const [endpoint, setEndPoint] = useState<{
    url: string;
    method: string;
  }>({ url: "", method: "get" });

  const [urlValues, setUrlValues] =
    useState<Scenario["parameter_values"]>(null);
  const { copy } = useCopyToClipboard();
  const [isEditorHighlighted, highlightEditor, onHighlightEditorAnimationEnd] =
    useHighlight();

  const {
    data: sandboxConfig,
    isLoading,
    isError,
    error,
  } = useMoneyHashQuery({
    queryKey: ["sandboxConfig", { type: type[0] }],
    queryFn: ({ signal }) =>
      getSandboxConfig({
        type: type[0],
        signal,
      }),
    staleTime: Infinity,
    enabled: !!type,
  });

  const mappedUrl = useMemo(() => {
    if (!urlValues) return endpoint.url;

    return Object.entries(urlValues).reduce((acc, [key, value]) => {
      if (!value) return acc;
      return acc.replace(`${`$`}{${key}}`, value);
    }, endpoint.url);
  }, [endpoint.url, urlValues]);

  useEffect(() => {
    if (sandboxConfig?.organization_api_key) {
      setOrganizationApiKey([
        sandboxConfig?.organization_api_key,
        { key: sandboxConfig?.organization_api_key, name: "" },
      ]);
    }
  }, [sandboxConfig]);

  useUpdateEffect(() => {
    if (account[0]) {
      setAccountApiKey(null);
    } else {
      setAccountApiKey([
        "",
        { key: "MoneyHash API Account", name: "MoneyHash Demo Account" },
      ]);
    }
  }, [account]);

  return (
    <SandboxProviderContext.Provider
      value={{
        setUrlValues,
        isSubmitting,
        setIsSubmitting,
        account,
        setAccount,
        accountApiKey,
        organizationApiKey,
        isLive,
        setIsLive,
        isMHDemoKey,
        type,
        setType,
        editor,
        copy,
        isEditorHighlighted,
        highlightEditor,
        onHighlightEditorAnimationEnd,
        sandboxConfig,
        isLoading,
        mappedUrl,
        scenario,
        setScenario,
        setEditor,
        setEndPoint,
        isError,
        error,
        setAccountApiKey,
        urlValues,
        endpoint,
        setOrganizationApiKey,
      }}
    >
      {children}
    </SandboxProviderContext.Provider>
  );
};

export function useSandbox() {
  const context = React.useContext(SandboxProviderContext);
  if (context === undefined) {
    throw new Error("useSandbox must be used within a SandboxProviderContext");
  }

  return context;
}
