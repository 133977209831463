// A helper function to parse query params to remove brackets '[]' from array-value params names
// Example: { param1: [1, 2], param2: 3 } => "param1=1&param1=2&param2=3" instead of "param1[]=1&param1[]=2&param2=3"
export const parseParams = (params: Record<string, unknown>) => {
  const parsedParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value === undefined || value === null) continue;

    const trimmedKey = key.trim();

    if (Array.isArray(value)) {
      for (const element of value) {
        if (element === undefined || element === null) continue;

        const trimmedElement = `${element}`.trim();
        parsedParams.append(trimmedKey, trimmedElement);
      }
    } else {
      const trimmedValue = `${value}`.trim();
      parsedParams.append(trimmedKey, trimmedValue);
    }
  }

  return parsedParams.toString();
};
