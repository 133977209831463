import * as React from "react";
import { axiosApiInstance } from "../pages/api/axios";

const defaultValues = {
  account: null,
  isPlatformConnectMode: false,
  error: null,
  fetchActiveConnectedProvider,
};
const PlatformConnectContext = React.createContext(defaultValues);

export const PlatformConnectProvider = ({ children }) => {
  const [isPlatformConnectMode, setIsPlatformConnectMode] =
    React.useState(false);
  const [account, setAccount] = React.useState(null);
  const [error, setError] = React.useState(null);

  async function fetchAccountData(accessToken) {
    try {
      const response = await axiosApiInstance.get(
        `${process.env.NEXT_PUBLIC_API_BASE}/accounts/get-from-access-token/`,
        { params: { access_token: accessToken } }
      );

      setAccount(response?.data?.data);
    } catch (error) {
      if (error.response?.status === 401) {
        setError(new Error("This access token is not valid."));

        return;
      }

      setError(error);
    }
  }

  React.useEffect(() => {
    if (!isPlatformConnectPage()) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const urlAccessToken = urlParams.get("access_token");
    if (urlAccessToken) {
      setIsPlatformConnectMode(true);
      fetchAccountData(urlAccessToken);

      return;
    }
  }, []);

  const value = {
    account,
    isPlatformConnectMode,
    error,
    fetchActiveConnectedProvider,
  };

  return (
    <PlatformConnectContext.Provider value={value}>
      {children}
    </PlatformConnectContext.Provider>
  );
};

export const PlatformConnectConsumer = ({ children }) => {
  return (
    <PlatformConnectContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            "PlatformConnectConsumer must be used within a PlatformConnectProvider"
          );
        }

        return children(context);
      }}
    </PlatformConnectContext.Consumer>
  );
};

export function usePlatformConnect() {
  const context = React.useContext(PlatformConnectContext);
  if (context === undefined) {
    throw new Error(
      "usePlatformConnect must be used within a PlatformConnectProvider"
    );
  }

  return context;
}

export function isUsingPlatformConnectAccessToken() {
  const urlParams = new URLSearchParams(window.location.search);
  const usingPlatformAccessToken = urlParams.has("access_token");

  return usingPlatformAccessToken;
}

export function isPlatformConnectPage() {
  const path = window?.location?.pathname?.split("/")[1];

  return ["platform-connect", "connections", "providers"].includes(path);
}

async function fetchActiveConnectedProvider() {
  try {
    const response = await axiosApiInstance.get("/providers/connected/", {
      params: { is_active: true, limit: 1 },
    });

    return response?.data;
  } catch (error) {
    console.error(error);
  }
}
