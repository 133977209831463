import {
  useQuery,
  type UseQueryOptions,
  type QueryKey,
} from "@tanstack/react-query";
import { ListMeta } from "../components/Table/types";

export type QueryResponse<TData> = {
  data: TData;
  count?: number;
  next?: null | string;
  previous?: null | string;
  status?: {
    code: number;
    message: string;
    errors: Record<string, string>[];
  };
  list_meta?: ListMeta;
};

export type ErrorResponse = {
  response?: {
    data: { status: { code: number; errors: Record<string, string>[] } };
  };
};

export function useMoneyHashQuery<
  TQueryFnData,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TError = ErrorResponse
>({
  queryKey,
  queryFn,
  ...options
}: {
  queryKey: TQueryKey;
  queryFn: ({
    signal,
  }: {
    signal?: AbortSignal;
  }) => Promise<QueryResponse<TQueryFnData>>;
} & Omit<
  UseQueryOptions<
    QueryResponse<TQueryFnData>,
    TError,
    QueryResponse<TData>,
    TQueryKey
  >,
  "queryKey" | "queryFn"
>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, status, ...restQuery } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      return queryFn({ signal });
    },
    ...options,
  });

  return {
    data: data?.data,
    count: data?.count,
    next: data?.next,
    previous: data?.previous,
    status: data?.status,
    listMeta: data?.list_meta,
    ...restQuery,
  };
}
