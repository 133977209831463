import React, { useState, useEffect, useReducer } from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { ThemeProvider, useTheme } from "next-themes";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Lottie from "lottie-light-react";
import loaderAnimation from "../public/lotties/loader.json";
import { RouteGuard } from "../components/RouteGuard";
import { ToastWrapper } from "../components/ToastWrapper";
import HotJar from "../components/scripts/HotJar";
import AppCues from "../components/scripts/AppCues";
import Zendesk from "../components/scripts/Zendesk";
import UserProvider, { useUserContext } from "../contexts/UserProvider";
import AnalyticsProvider from "../features/analytics/analyticsProvider";
import { GlobalSearchProvider } from "../contexts/GlobalSearchProvider";

import {
  PlatformConnectProvider,
  PlatformConnectConsumer,
} from "../contexts/PlatformConnectProvider";
import { TestModeProvider } from "../contexts/TestModeProvider";
import "../styles/globals.css";
import { TooltipProvider } from "ui";
import { useFeatureFlags } from "../hooks/query";
import { Userpilot } from "userpilot";
import useUpdateEffect from "../hooks/useUpdateEffect";

const ReactQueryDevtools = dynamic(() =>
  import("@tanstack/react-query-devtools").then((mod) => mod.ReactQueryDevtools)
);

const SideNav = dynamic(() => import("../components/SideNav/SideNav"), {
  ssr: false,
  loading: () => <div />,
});
const DashboardHeader = dynamic(
  () =>
    import("../components/DashboardHeader/index").then(
      (mod) => mod.DashboardHeader
    ),
  { ssr: false, loading: () => <div /> }
);
const PlatformConnectHeader = dynamic(
  () =>
    import("../components/PlatformConnectHeader").then(
      (mod) => mod.PlatformConnectHeader
    ),
  { ssr: false, loading: () => <div /> }
);
const Onboarding = dynamic(
  () => import("../features/onboarding").then((mod) => mod.Onboarding),
  {
    ssr: false,
    loading: () => <div />,
  }
);

function performActionsOnRouteChange() {
  // Dismiss generic errors on route change
  toast.dismiss("generic-error");

  // Reload Userpilot
  Userpilot.reload();

  window.Appcues?.page();
}

function GlobalLoaderAnimation() {
  return (
    <div className="fixed inset-0 z-[999] flex items-center justify-center bg-foundations-default">
      <Lottie
        loop
        style={{ maxWidth: "200px" }}
        animationData={loaderAnimation}
      />
    </div>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const InjectReactQueryDevtools = () => {
  const [showDevtools, setShowDevtools] = useState(false);
  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((s) => !s);
  }, []);
  if (!showDevtools) return null;
  return <ReactQueryDevtools initialIsOpen={false} />;
};

// HOC to provide the providers needed for App component
function withProviders(Component) {
  return function wrapWithProviders(props) {
    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider
          themes={["light", "dark"]}
          enableSystem={false}
          disableTransitionOnChange
        >
          <TooltipProvider>
            <UserProvider>
              <AnalyticsProvider>
                <ToastWrapper />
                <AppCues />
                <Component {...props} />
              </AnalyticsProvider>
            </UserProvider>
          </TooltipProvider>
        </ThemeProvider>
        <InjectReactQueryDevtools />
      </QueryClientProvider>
    );
  };
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    id: userId,
    isOnboarded,
    isLoading: isLoadingCurrentUser,
  } = useUserContext();
  const { isLoadingFeatureFlags } = useFeatureFlags();

  /**
   * Some colors are generated as js variable via getColorFromCssVariable
   * which will need to re-run
   */
  const { theme } = useTheme();
  const [, forceRender] = useReducer((state) => !state, false);
  useUpdateEffect(() => {
    forceRender();
  }, [theme]);

  useEffect(() => {
    router.events.on("routeChangeComplete", performActionsOnRouteChange);

    return () => {
      router.events.off("routeChangeComplete", performActionsOnRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (
      !userId ||
      !["staging", "production"].includes(process.env.NEXT_PUBLIC_ENV)
    )
      return;

    Userpilot.initialize("NX-b281abdd");
  }, [userId]);

  if (Component.publicPath) {
    return <Component {...pageProps} />;
  }

  if (router.isReady && !router.query.access_token && userId && !isOnboarded) {
    return <Onboarding />;
  }

  return (
    <PlatformConnectProvider>
      <TestModeProvider parentPath={Component.parentPath}>
        <GlobalSearchProvider>
          <RouteGuard>
            <Head>
              <>
                <title>{process.env.NEXT_PUBLIC_APP_TITLE} | Dashboard</title>
                <meta name="description" content="MoneyHash dashboard" />
                <meta
                  name="viewport"
                  content="initial-scale=1.0, width=device-width"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/apple-touch-icon.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="icon" href="/favicon.ico" />
              </>
            </Head>
            <HotJar />
            <div className="flex h-screen overflow-hidden bg-foundations-default">
              <PlatformConnectConsumer>
                {({ isPlatformConnectMode }) => (
                  <>
                    {!isPlatformConnectMode && <Zendesk />}
                    {!isPlatformConnectMode && (
                      <SideNav
                        sidebarOpen={sidebarOpen}
                        onClose={() => setSidebarOpen(false)}
                      />
                    )}

                    <div className="flex w-0 flex-1 flex-col overflow-hidden">
                      {!isPlatformConnectMode && (
                        <DashboardHeader
                          setSidebarOpen={setSidebarOpen}
                          hideTestModeBanner={Component.hideTestModeBanner}
                        />
                      )}
                      {isPlatformConnectMode && <PlatformConnectHeader />}

                      {(isLoadingFeatureFlags || isLoadingCurrentUser) &&
                      !isPlatformConnectMode ? (
                        <GlobalLoaderAnimation />
                      ) : (
                        <Component {...pageProps} />
                      )}
                    </div>
                  </>
                )}
              </PlatformConnectConsumer>
            </div>
          </RouteGuard>
        </GlobalSearchProvider>
      </TestModeProvider>
    </PlatformConnectProvider>
  );
}

export default withProviders(MyApp);
