import { Input } from "ui";
import { Scenario } from "../types";

interface UrlValuesProps {
  urlValues: NonNullable<Scenario["parameter_values"]>;
  setUrlValues: React.Dispatch<
    React.SetStateAction<Scenario["parameter_values"]>
  >;
}

export function UrlValues({ urlValues, setUrlValues }: UrlValuesProps) {
  return (
    <div>
      <h3 className="text-sm font-medium text-foundations-bold">Parameter</h3>
      <div className="mt-1 space-y-2">
        {Object.entries(urlValues).map(([key, value]) => (
          <div
            key={key}
            className="flex overflow-hidden rounded-md border border-neutral-300 focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-info-bold"
          >
            <span className="border-r border-foundations-subtle px-3 py-2 text-sm text-foundations-subtle">
              {key}
            </span>
            <Input
              name={key}
              placeholder=""
              value={value}
              onChange={(value) =>
                setUrlValues((prev) => ({ ...prev, [key]: value }))
              }
              className="rounded-none border-none focus:ring-0"
              containerClassName="flex-1"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
