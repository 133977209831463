import { CheckIcon, DuplicateIcon } from "icons/outline";
import { Badge, IconButton, useCopyToClipboard } from "ui";

interface EndPointProps {
  method: string;
  url: string;
}

export function EndPoint({ method, url }: EndPointProps) {
  const { isCopied, copy } = useCopyToClipboard();
  return (
    <div className="flex items-center space-x-2 overflow-hidden rounded border border-foundations-subtle bg-neutral-50 px-2 py-1.5">
      <Badge className="shrink-0 rounded uppercase text-foundations-subtle">
        {method}
      </Badge>
      <span className="flex-1 overflow-x-auto whitespace-nowrap text-xs text-foundations-subtle scrollbar-hide">
        {url}
      </span>
      <IconButton
        className="shrink-0 text-info hover:text-blue-600 active:text-info"
        size="xs"
        variant="tertiary"
        disabled={isCopied}
        onClick={() => copy(url)}
      >
        {isCopied ? (
          <CheckIcon className="text-green-600" />
        ) : (
          <DuplicateIcon />
        )}
      </IconButton>
    </div>
  );
}
