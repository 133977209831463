import { useState } from "react";
import { DesktopComputerIcon, DeviceMobileIcon } from "icons/outline";
import { OptionsGroup, Option, cn } from "ui";

interface IframePreviewProps {
  url: string;
}
export function IframePreview({ url }: IframePreviewProps) {
  const [previewDevice, setPreviewDevice] = useState("desktop");

  return (
    <div className="flex flex-1 flex-col overflow-hidden rounded-lg border border-foundations-subtle bg-foundations-default">
      <div className="flex justify-end border-b border-b-foundations-subtle p-3">
        <OptionsGroup value={previewDevice} onValueChange={setPreviewDevice}>
          <Option value="desktop">
            <DesktopComputerIcon className="h-4 w-4" />
          </Option>
          <Option value="mobile">
            <DeviceMobileIcon className="h-4 w-4" />
          </Option>
        </OptionsGroup>
      </div>
      <div className="flex-1 bg-neutral-50">
        <iframe
          key={url}
          className={cn(
            "mx-auto h-full w-full bg-neutral-white transition-all duration-500",
            {
              "max-w-full ": previewDevice === "desktop",
              "my-10 max-h-[600px] max-w-sm rounded-xl shadow-lg":
                previewDevice === "mobile",
            }
          )}
          src={url}
        />
      </div>
    </div>
  );
}
